import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonToast,
  IonIcon,
  IonLoading,
  IonImg
} from '@ionic/react';

import {
  alertCircleSharp
} from 'ionicons/icons';

import './Signup.css';
import { useState } from 'react';
import { setUserState } from '../redux/actions';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { useDispatch } from 'react-redux';

import { app, auth } from '../firebase';

const Signup: React.FC = () => {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showToast_error_passwordMismatch, setShowToast_error_passwordMismatch] = useState(false);
  const [showToast_error_accountExists, setShowToast_error_accountExists] = useState(false);

  const [busy, setBusy] = useState(false);

  const dispatch = useDispatch();

  function registerUser(){
    setBusy(true);
    if (password != confirmPassword){
      setShowToast_error_passwordMismatch(true);
      setBusy(false);
      return;
    }

    createUserWithEmailAndPassword(auth, username, password).then((userCredential) => {
      const user = userCredential.user;
      sendEmailVerification(userCredential.user);
      dispatch(setUserState(user));
      window.history.replaceState({}, '', '/Integrations');
      window.location.href = "/Integrations";
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      setShowToast_error_accountExists(true);
      console.log(errorCode, errorMessage);
    });

    setBusy(false);
  }

  return (
    <IonPage>
      <IonContent fullscreen className="login-background">
        <div className="login-background-overlay">
          <div className="login-area">
            <IonImg className="rollover-rep-logo-login" src="https://i.imgur.com/akmZhdi.png" />
            <IonItem>
              <IonInput
                placeholder="Email"
                onIonChange={(event: any) => setUsername(event.target.value)}
              />
            </IonItem>
            <IonItem>
              <IonInput
                placeholder="Password"
                onIonChange={(event: any) => setPassword(event.target.value)}
                type="password"
              />
            </IonItem>
            <IonItem>
              <IonInput
                placeholder="Confirm Password"
                onIonChange={(event: any) => setConfirmPassword(event.target.value)}
                type="password"
              />
            </IonItem>
            <IonButton color="primary" expand="full" onClick={registerUser}>
              Sign Up
            </IonButton>
            <p className="sign-up-text"> Already have an account? <a href="/Login"> Log In. </a></p>
          </div>
        </div>
        <IonToast
          isOpen={showToast_error_passwordMismatch}
          onDidDismiss={() => setShowToast_error_passwordMismatch(false)}
          message="Passwords Do Not Match"
          duration={2000}
          color="danger"
          icon={alertCircleSharp}
        />

        <IonToast
          isOpen={showToast_error_accountExists}
          onDidDismiss={() => setShowToast_error_accountExists(false)}
          message="An account with that email already exists. Contact support@rolloverrep.com if you need help."
          duration={2000}
          color="danger"
          icon={alertCircleSharp}
        />

        <IonLoading message="Please wait..." duration={0} isOpen={busy} />
      </IonContent>
    </IonPage>
  );
};

export default Signup;
