import React, { useEffect, useState } from "react";

import {
  writeUserSalesforceCode,
  readUserSalesforceCode
} from '../../firebase';

import axios from 'axios';

import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonCard, IonCardHeader, IonCardTitle, IonImg, IonCardContent, IonButton, IonIcon, IonItem, IonLabel } from '@ionic/react';
import './Success.css';
import { checkmarkDoneCircleOutline } from 'ionicons/icons';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

var Success: React.FC = () => {

  const location = useLocation();

  var code = new URLSearchParams(location.search).get("code");
  if (!code) {
    code = "";
  }

  var test = new URLSearchParams(location.search).get("test");
  if (!test) {
    test = "";
  }

  const user_id = useSelector((state: any) => state.userData.uid);

  writeUserSalesforceCode(user_id, code);

  var get_code_url = 'https://ykgj4h1hub.execute-api.us-east-1.amazonaws.com/v2/salesforce/auth?code='+code+'&user_id='+user_id+'&test='+test;

  const sendRequest = () => {
    return axios.get(get_code_url).then((response) => {
            console.log(response);
            return response.data;
        }
    )};

  sendRequest();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Success</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen >
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Success</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="success-page-container">
          <IonIcon color="success" className="integration-success-icon" ios={checkmarkDoneCircleOutline} md={checkmarkDoneCircleOutline} />
          <IonTitle>
            You have successfully connected your account
          </IonTitle>
          <IonButton color="primary" className="success-page-back-button" href="/Integrations">Back</IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
}

export default Success;
