import './ExploreContainer.css';

import Integrations from './containers/Integrations';
import { useParams } from 'react-router';


interface ContainerProps {
  name: string;
}

const ExploreContainer: React.FC<ContainerProps> = ({ name }) => {

  switch (name) {
    case 'Integrations':
      return (
        <Integrations />
      );
    default:
    return (
      <div className="container">
        <strong>{name}</strong>
        <p>Coming soon...</p>
      </div>
    );
  }
};

export default ExploreContainer;
