import React, { useEffect, useState } from "react";

import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonCard, IonCardHeader, IonCardTitle, IonImg, IonCardContent, IonButton, IonIcon, IonItem, IonLabel, IonCheckbox } from '@ionic/react';
import './Integrations.css';
import { checkmarkCircleOutline, checkmarkCircleSharp, archiveOutline, archiveSharp, bookmarkOutline, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp, homeOutline, homeSharp, globeOutline, globeSharp, settingsOutline, settingsSharp } from 'ionicons/icons';

import { useLocation } from 'react-router-dom';

var Integrations: React.FC = () => {

  const location = useLocation();

  var code = new URLSearchParams(location.search).get("code");

  var app_host = "https://login.salesforce.com";
  var app_host_test = "https://test.salesforce.com";
  var client_id = "3MVG9kBt168mda_8TYC7I1K4LjKcOuTiTsu8saIFMG_vFeU42TPbsfWwqTy4sFkyz_lvib_LD8PZ6k2Yz.bRL";
  //var redirect_uri = "https://rollover-rep-307a0.web.app/api/callback"; //TODO - remove, deprecated
  const [redirectUri, setRedirectUri] = useState("https://app.rolloverrep.com/api/callback");

  const [salesforceConnectUrl, setSalesforceConnectUrl] = useState(app_host+"/services/oauth2/authorize?client_id="+client_id+"&redirect_uri="+redirectUri+"&response_type=code");

  return (
    <IonCard className="integrations-card">
      <IonCardHeader className="integrations-card-header">
        <IonCardTitle className="integration-title">
          Salesforce
        </IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonButton color="primary" className="connect-button" href={salesforceConnectUrl}>Connect</IonButton>
        <IonItem>
          <IonCheckbox slot="start" onIonChange={(e) => {
            console.log(e);

            if (e.detail.checked){
              setRedirectUri("https://app.rolloverrep.com/api/callback?test=true");
              setSalesforceConnectUrl(app_host_test+"/services/oauth2/authorize?client_id="+client_id+"&redirect_uri="+"https://app.rolloverrep.com/api/callback?test=true"+"&response_type=code")
            }
            else {
              setRedirectUri("https://app.rolloverrep.com/api/callback");
              setSalesforceConnectUrl(app_host+"/services/oauth2/authorize?client_id="+client_id+"&redirect_uri="+"https://app.rolloverrep.com/api/callback"+"&response_type=code")
            }

          }}></IonCheckbox>
          <IonLabel>Use Sandbox</IonLabel>
        </IonItem>
      </IonCardContent>
      {/* <IonImg className="integration-icon" src="https://cdn.worldvectorlogo.com/logos/salesforce-2.svg" />*/ }
    </IonCard>
  );
}

export default Integrations;
