import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  IonSpinner,
  setupIonicReact,
  useIonViewDidEnter,
 } from '@ionic/react';
 
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';

import Menu from './components/Menu';
import Page from './pages/Page';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Reset from './pages/Reset';

import Success from './components/containers/Success';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import { app, auth, user, getCurrentUser } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { setUserState } from './redux/actions';
import { useDispatch } from 'react-redux';

setupIonicReact();

const RoutingSystemLoggedIn: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu />
          <IonRouterOutlet id="main">
            <Route path="/" exact={true}>
              <Redirect to="/Integrations" />
            </Route>
            <Route path="/:name" exact={true}>
              <Page />
            </Route>
            <Route path="/api/callback" exact={true}>
              <Success />
            </Route>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

const RoutingSystemLoggedOut: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <IonRouterOutlet id="main">
            <Route>
              <Redirect to="/Login"/>
            </Route>
            <Route path="/Login" exact={true}>
              <Login />
            </Route>
            <Route path="/Signup" exact={true}>
              <Signup />
            </Route>
            <Route path="/Reset" exact={true}>
              <Reset />
            </Route>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

const App: React.FC = () => {

  const [ loggedIn, setLoggedIn] = useState(false);
  const [ busy, setBusy] = useState(true);
  const dispatch = useDispatch();

  useEffect(()=>{
    getCurrentUser().then( (user: any) => {
      if (user){
        dispatch(setUserState(user));
        setLoggedIn(true);
        setBusy(false);
      }
      else {
        setLoggedIn(false);
        setBusy(false);
      }
    }).catch(e => {
      console.log(e);
      setLoggedIn(false);
      setBusy(false);
    });
  });

  return (
    <IonApp>
      {busy ? <IonSpinner /> : loggedIn ? <RoutingSystemLoggedIn /> : <RoutingSystemLoggedOut />}
    </IonApp>
  );
};

export default App;
