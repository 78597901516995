import { initializeApp } from 'firebase/app';

import {
  getAuth,
  onAuthStateChanged,
  getRedirectResult,
  signInWithEmailAndPassword,
  signOut
} from 'firebase/auth';

import {
  getDatabase,
  ref,
  set,
  update,
  onValue
} from "firebase/database";

import { getAnalytics } from "firebase/analytics";


const config = {
  apiKey: "AIzaSyBmpBdO2Oy0GMx8Ya5MqcPBEP_pOQ_6628",
  authDomain: "rollover-rep-307a0.firebaseapp.com",
  databaseURL: "https://rollover-rep-307a0-default-rtdb.firebaseio.com",
  projectId: "rollover-rep-307a0",
  storageBucket: "rollover-rep-307a0.appspot.com",
  messagingSenderId: "37686270375",
  appId: "1:37686270375:web:554b05b942f129701c3e82",
  measurementId: "G-D465H9SMSS"
};

const app = initializeApp(config);
const database = getDatabase(app);
const auth = getAuth();
const analytics = getAnalytics(app);

//TODO - Review
var user = auth.currentUser;

export function readUserSalesforceCode(userId: string) {
  return new Promise(function (resolve, reject) {
    const userRef = ref(database, 'users/' + userId);

    var code: any;

    onValue(userRef, (snapshot) => {
      const data = snapshot.val();
      if (data && data.salesforce_code){
        code = data.salesforce_code;
        resolve(code);
      }
      else {
        code = "";
        reject(code);
      }
    });
  });
}

export function writeUserSalesforceCode(userId: string, code: string) {

  const updates: any = {};
  updates['/users/' + userId + '/salesforce_code'] = code;

  var result:any;

  update(ref(database), updates).then((res) => {
    console.log("Code Saved"); // TODO - remove debugging
    result = res;
  })
  .catch((error) => {
    console.log(error);
    result = "error";
  });

  console.log(result);
  return result;
}

export function getCurrentUser(){
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, (u: any) => {
      if (u) {
        //console.log("User Logged In from firebase.tsx: " + u.uid); //debugging TODO - remove
        resolve(u);
      }
      else {
        reject(null);
      }
    });
    unsubscribe();
  });
}

export async function signInUserWithEmail(email: string, password: string){
  signInWithEmailAndPassword(auth, email, password).then((userCredential) => {
    const user = userCredential.user;
    console.log(user);
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(errorCode, errorMessage);
  });
}

export function logoutUser(){
  return signOut(auth).then(() => {
    window.history.replaceState({}, '', '/Login');
    window.location.href = "/Login";
  }).catch((error) => {
    // An error happened.
    console.log(error);
  });
}

export {app, auth, user, database};
