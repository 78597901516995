import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonItem,
  IonLabel,
  IonInput,
  IonToast,
  IonIcon,
  IonLoading,
  IonImg
} from '@ionic/react';

import {
  alertCircleSharp
} from 'ionicons/icons';

import './Login.css';
import { useState } from 'react';
import { setUserState } from '../redux/actions';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useDispatch } from 'react-redux';

import { app, auth } from '../firebase';

const Login: React.FC = () => {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showToast_error, setShowToast_error] = useState(false);
  const [showToast_passwordReset, setShowToast_passwordReset] = useState(false);
  const [busy, setBusy] = useState(false);

  const dispatch = useDispatch();

  function loginUser(){
    setBusy(true);
    signInWithEmailAndPassword(auth, username, password).then((userCredential) => {
      const user = userCredential.user;
      dispatch(setUserState(user));
      window.history.replaceState({}, '', '/Integrations');
      window.location.href = "/Integrations";
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      setShowToast_error(true);
      console.log(errorCode, errorMessage);
    });
    setBusy(false);
  }

  return (
    <IonPage>
      <IonContent fullscreen className="login-background">
        <div className="login-background-overlay">
          <div className="login-area">
            <IonImg className="rollover-rep-logo-login" src="https://i.imgur.com/akmZhdi.png" />
            <IonItem>
              <IonInput
                placeholder="Email"
                onIonChange={(event: any) => setUsername(event.target.value)}
              />
            </IonItem>
            <IonItem>
              <IonInput
                placeholder="Password"
                onIonChange={(event: any) => setPassword(event.target.value)}
                type="password"
              />
            </IonItem>
            <IonButton color="primary" expand="full" onClick={loginUser}>
              Login
            </IonButton>
            <p className="sign-up-text"> Don't have an account? <a href="/Signup"> Sign up. </a></p>
            <p className="sign-up-text"> Need help logging in? <a href="/Reset"> Reset your password.</a></p>
          </div>
        </div>
        <IonToast
          isOpen={showToast_error}
          onDidDismiss={() => setShowToast_error(false)}
          message="Invalid Username or Password"
          duration={2000}
          color="danger"
          icon={alertCircleSharp}
        />
        <IonToast
          isOpen={showToast_passwordReset}
          onDidDismiss={() => setShowToast_passwordReset(false)}
          message="Invalid Username or Password"
          duration={2000}
          color="danger"
          icon={alertCircleSharp}
        />

        <IonLoading message="Please wait..." duration={0} isOpen={busy} />
      </IonContent>
    </IonPage>
  );
};

export default Login;
